var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 3 },"wrapper-col":{ span: 16 }}},[_c('a-form-item',{attrs:{"label":_vm.title}},[_c('div',[_c('editor',{attrs:{"apiKey":"hzdffjjoc5lcw2zpnv806co8tnj5f9h0cp8z3np68sn2il7m","initialValue":_vm.value.value,"init":{
            height: 500,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap',
              'searchreplace visualblocks code fullscreen',
              'print preview anchor insertdatetime media',
              'paste code help wordcount table' ],
            toolbar:
              'undo redo | formatselect | bold italic | \
      alignleft aligncenter alignright | \
      bullist numlist outdent indent | help',
          }},on:{"change":_vm.onEditorChange},model:{value:(_vm.value.value),callback:function ($$v) {_vm.$set(_vm.value, "value", $$v)},expression:"value.value"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }