<template>
  <section>
    <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 10 }">
      <a-form-item label="Tel raqam">
        <a-input
          type="tel"
          v-model="value.phone_number"
          :value="value.phone_number"
        />
      </a-form-item>
      <a-form-item label="Email">
        <a-input type="email" v-model="value.email" :value="value.email" />
      </a-form-item>
      <a-form-item label="Logo">
        <a-input
          type="textarea"
          rows="4"
          v-model="value.logo"
          :value="value.logo"
        />
      </a-form-item>
      <a-form-item label="App store">
        <a-input
          type="url"
          v-model="value.app_store"
          :value="value.app_store"
        />
      </a-form-item>
      <a-form-item label="Play Market">
        <a-input
          type="url"
          v-model="value.play_market"
          :value="value.play_market"
        />
      </a-form-item>
      <a-form-item label="Telegram">
        <a-input type="url" v-model="value.telegram" :value="value.telegram" />
      </a-form-item>
      <a-form-item label="Instagram">
        <a-input
          type="url"
          v-model="value.instagram"
          :value="value.instagram"
        />
      </a-form-item>
      <a-form-item label="Facebook">
        <a-input type="url" v-model="value.facebook" :value="value.facebook" />
      </a-form-item>
      <a-form-item label="You tube">
        <a-input type="url" v-model="value.youtube" :value="value.youtube" />
      </a-form-item>
      <a-form-item label="Adress">
        <a-input type="text" v-model="value.address" :value="value.address" />
      </a-form-item>
      <a-form-item label="Xarita">
        <a-input
          type="textarea"
          rows="4"
          cols="6"
          v-model="value.map"
          :value="value.map"
        />
      </a-form-item>
    </a-form>
  </section>
</template>

<script>
export default {
  props: {
    value: Object,
    default: () => ({}),
  },
  model: {
    prop: 'value',
    event: 'change',
  },
};
</script>
