<template>
  <div class="container">
    <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 16 }">
      <a-form-item :label="title">
        <div>
          <editor
            apiKey="hzdffjjoc5lcw2zpnv806co8tnj5f9h0cp8z3np68sn2il7m"
            :initialValue="value.value"
            v-model="value.value"
            @change="onEditorChange"
            :init="{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap',
                'searchreplace visualblocks code fullscreen',
                'print preview anchor insertdatetime media',
                'paste code help wordcount table',
              ],
              toolbar:
                'undo redo | formatselect | bold italic | \
        alignleft aligncenter alignright | \
        bullist numlist outdent indent | help',
            }"
          >
          </editor>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    title: String,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  components: {
    editor: Editor,
  },
  data() {
    return {
      content: '<h2>I am Example</h2>',
    };
  },
  methods: {
    onEditorChange(data) {
      console.log(data);
    },
  },
};
</script>
