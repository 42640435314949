<template>
  <div class="container mt-3">
    <h4 class="ml-sm-2 ml-md-4 ml-lg-4">
      Kompaniya ijtimoiy tarmoqlari
    </h4>
    <edit-social-contact v-model="social"></edit-social-contact>
    <text-editor v-model="social" title="Jamoa haqida"></text-editor>
    <div class="container">
      <a-button
        class="bg-warning text-white  mb-5"
        size="large"
        @click="editSocial"
        >O'zgartirish</a-button
      >
    </div>

    <modal v-if="inValid" title="Xato" @close="closeModal">
      <p class="text-white bg-danger p-3 mt-3">
        Iltimos Telefon raqamni,adreesni va jamoa haqidagi malumotni kiriting ):
      </p>
    </modal>
  </div>
</template>

<script>
import EditSocialContact from '../../components/SocialContact/EditSocialContact.vue';
import TextEditor from '../../components/Products/TextEditor.vue';
import request from '../../api/fetchConfig';
export default {
  components: {
    EditSocialContact,
    TextEditor,
  },

  data() {
    return {
      inValid: false,
      social: {
        phone_number: '',
        email: '',
        logo: '',
        app_store: '',
        play_market: '',
        telegram: '',
        facebook: '',
        instagram: '',
        youtube: '',
        address: '',
        map: '',
        about_team: '',
      },
    };
  },
  methods: {
    closeModal(bool) {
      this.inValid = bool;
    },
    editSocial() {
      const obj = {
        phone_number: this.social.phone_number,
        address: this.social.address,
        about_team: this.social.value,
      };
      for (let i in obj) {
        if (obj[i] === '') {
          this.inValid = true;
          return;
        }
      }
      const data = this.social.value;
      delete this.social.value;
      this.social.about_team = data;
      this.patchSocial(this.social);
    },
    patchSocial(data) {
      request('contact/info-aboutus/1/', 'PATCH', data)
        .then((res) => {
          this.$notification['success']({
            message: 'Success',
            description: 'Serverda Social Contact ozgartirildi ):',
          });
          this.social = res;
        })
        .catch((error) => {
          this.$notification['error']({
            message: 'Xatolik',
            description: 'Serverda Xatolik Boldi ):' + error.message,
          });
        });
    },
  },

  created() {
    request('contact/info-aboutus/1/', 'GET').then((res) => {
      const data = res.about_team;
      for (let i in res) {
        if (i === 'about_team') {
          delete res.about_team;
        }
      }
      res.value = data;
      this.social = res;
    });
  },
};
</script>
